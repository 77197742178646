import { FC } from 'react';
import { ParagraphTextFragment } from '../../../generated/types';
import { Box, Heading } from '@chakra-ui/react';
import { HTMLText } from '../../Common/HTMLText';
import slugify from '../../../utils/slugify';

interface TextProps {
  data: ParagraphTextFragment;
}

export const Text: FC<TextProps> = ({ data }) => {
  return (
    <>
      {data.headline && (
        <Heading
          variant="textHeading"
          as="h2"
          id={data.addToTableOfContents ? slugify(data.headline) : undefined}
          mb={3}
        >
          {data.headline}
        </Heading>
      )}
      <HTMLText text={data.text} />
    </>
  );
};
